module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Best Mobile App Development Company - CodeMymobile', // Navigation and Site Title
  titleAlt: 'Best Mobile App Development Company - CodeMymobile', // Title for JSONLD
  description:
    'CodeMyMobile is a results-driven mobile app development company in India building highly secure, world-class high-performing mobile applications',
  keywords: [
    'Mobile App Development',
    'Mobile Application Developers',
    'Mobile Application Development',
    'Mobile Apps',
    'Apps',
    'Apple',
    'iOS',
    'Android',
    'Windows Phone',
    'App Development',
    'Web App Development',
    'Progressive Web Apps',
    'Mobility',
    'Mobile Apps',
    'Mobile Application Development',
    'Android Developers',
    'Android Development',
    'iOS Developers',
    'iOS Development',
    'Windows Phone Developers',
    'Windows Phone Development',
    'Mobile App Developers',
    'Cloud Apps',
    'Cloud Development',
    'Cloud App Development',
    'Big Data',
    'Big Data Apps',
    'Hadoop',
    'Hadoop Apps',
    'Hadoop Application Development',
  ],
  url: 'https://www.codemymobile.com', // Domain of your site. No trailing slash!
  siteUrl: 'https://www.codemymobile.com', // url + pathPrefix
  siteLanguage: 'en', // Language Tag on <html> element
  logo: 'static/logo/logo.png', // Used for SEO
  banner: 'static/logo/banner.png',
  // JSONLD / Manifest
  favicon: 'static/logo/logo.png', // Used for manifest favicon generation
  shortName: 'CodeMyMobile', // shortname for manifest. MUST be shorter than 12 characters
  author: 'CodeMyMobile', // Author for schemaORGJSONLD
  themeColor: '#3e7bf2',
  backgroundColor: '#d3e0ff',
  twitter: '@codemymobile', // Twitter Username
};
